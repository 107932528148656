type HeadersModule = typeof import('next/headers')

function getAdditionalContext() {
  if (typeof window === 'undefined' && typeof require !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const headers = (require('next/headers') as HeadersModule).headers()
    const referer = headers.get('referer')
    return { referer }
  }
  return {}
}
export default getAdditionalContext
