import getAdditionalContext from './getAdditionalContext'
import * as Sentry from '@sentry/nextjs'
import { type Reporter, createHoneybadger, createReporter } from '@betterplace/error-reporting'

const honeybadger = createHoneybadger({
  apiKey: process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY,
  environment: process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT,
  revision: process.env.NEXT_PUBLIC_HONEYBADGER_REVISION,
  tags: ['org'],
  projectRoot: 'webpack:///./',
  getAdditionalContext,
})

honeybadger.beforeNotify((notice) => {
  if (!notice) return

  notice.backtrace.forEach((line) => {
    if (line.file) {
      // TODO: we might have to adjust this depending on where the assets actually live...
      // webpack-internal:///(sc_server)/./src/...
      line.file = line.file
        .replace('webpack-internal:///', process.env.NEXT_PUBLIC_ASSET_URL)
        .replace('(sc_server)/.', '/frontend/apps/org')
    }
    return line
  })
})

const hb = createReporter(honeybadger)
const report: Reporter = (error, context) => {
  hb(error, context)
  const additionalContext = getAdditionalContext()
  // eslint-disable-next-line import/namespace
  Sentry.captureException(error, { ...context, data: additionalContext })
}
export default report
